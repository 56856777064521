import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import tagsImage from '../../images/home/coder.svg'
import similarImage from '../../images/home/simialr_images.svg'
import keywordHelper from '../../images/home/keyword_helper.svg'
import uploadImage from '../../images/home/upload.svg'
const Tagging = () => (
  <span>
    <Typography variant="body1" gutterBottom>
      We use artificial intelligence to give you the perfect keywords to make
      sure your content appears in more searches.
    </Typography>
    <Typography variant="h6" className="bullet-points">
      <span className="check-mark">✔</span> Detects faces, race, gender and age{' '}
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        woman, elderly, Asian
      </Typography>
      <br />
      <span className="check-mark">✔</span> Connects expressions to emotions{' '}
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        smile → happy
      </Typography>
      <br />
      <span className="check-mark">✔</span> Identifies landmarks
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        Grand Canyon
      </Typography>
      <br />
      <span className="check-mark">✔</span> Recognizes logos and brands
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        Coca-Cola
      </Typography>
      <br />
      <span className="check-mark">✔</span> Names objects and subjects
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        Cactus
      </Typography>
      <br />
      <span className="check-mark">✔</span> Connects images to a broader context{' '}
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        beach house → vacation
      </Typography>
    </Typography>
  </span>
)

const KeyWordHelper = () => (
  <span>
    <Typography variant="body1" gutterBottom>
      Our Keyword Helper gives you the following features to help you refine
      your set of keywords.
      <br />
    </Typography>
    <Typography variant="h6" className="bullet-points">
      <span className="check-mark">✔</span>Synonyms
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → meal
      </Typography>
      <br />
      <span className="check-mark">✔</span>Trigger words:
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → toast
      </Typography>
      <br />
      <span className="check-mark">✔</span>Hypernyms:
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → food
      </Typography>
      <br />
      <span className="check-mark">✔</span>Direct Hyponyms:
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → cereal
      </Typography>
      <br />
      <span className="check-mark">✔</span>Popular nouns:
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → brunch
      </Typography>
      <br />
      <span className="check-mark">✔</span>Popular adjectives:
      <br className="example-breaker" />
      <Typography variant="overline" className="example-text">
        {' '}
        breakfast → healthy
      </Typography>
      <br />
    </Typography>
  </span>
)

export default () => (
  <section id="features" className="usecases">
    <div className="section-content">
      <Grid container alignContent="center" alignItems="center" spacing={32}>
        {/* AUTOMATE TAGS */}
        <Grid item xs={12} sm={7}>
          <Typography variant="h3" className="home-page-section-title">
            Use automated keywords.
          </Typography>
          <img
            className="usecases-img phone-only"
            src={tagsImage}
            alt="Automated Keywords"
          />
          <Tagging />
        </Grid>

        <Grid item xs={12} sm={5} className="tablet-portrait-up">
          <img
            className="usecases-img"
            src={tagsImage}
            alt="Automated Keywords"
          />
        </Grid>

        {/* SIMILAR PHOTOS */}
        <Grid item xs={12} sm={6} className="tablet-portrait-up">
          <img
            alt="Similar Images"
            className="usecases-img"
            src={similarImage}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h3" className="home-page-section-title">
            Appear in more searches.
          </Typography>
          <img
            alt="Similar Images"
            className="usecases-img phone-only"
            src={similarImage}
          />
          <Typography variant="body1" gutterBottom>
            We show you how similar photos are being used in the real world and
            what a potential buyer might be typing into the search engine to
            find a photo like yours.
          </Typography>
          <Typography variant="body1" gutterBottom>
            For example, your photo of breakfast cereal may often be used by
            gluten-free bloggers. We show you similar images and buyer- specific
            words like ‘gluten-free’ to include into your keywords to make sure
            your photo will surface on more buyers’ searches.
          </Typography>
        </Grid>

        {/* KEYWORD HELPER */}
        <Grid item xs={12} sm={7}>
          <Typography variant="h3" className="home-page-section-title">
            Use the Keyword Helper.
          </Typography>
          <img
            alt="Keyword Helper"
            className="usecases-img phone-only"
            src={keywordHelper}
          />
          <KeyWordHelper />
        </Grid>

        <Grid item xs={12} sm={5} className="tablet-portrait-up">
          <img
            alt="Keyword Helper"
            className="usecases-img"
            src={keywordHelper}
          />
        </Grid>

        {/* MULTI UPLOAD */}
        <Grid item xs={12} sm={6} className="tablet-portrait-up">
          <img
            alt="Upload to multiple agents"
            className="usecases-img"
            src={uploadImage}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h3" className="home-page-section-title">
            Upload to multiple agencies with a single click.
          </Typography>

          <img
            alt="Upload to multiple agents"
            className="usecases-img phone-only"
            src={uploadImage}
          />

          <Typography variant="body1" gutterBottom>
            Upload media files, keywords and descriptions to multiple stock
            agents. We help you get your content uploaded faster so you can get
            out there and make more!
          </Typography>
        </Grid>
      </Grid>
    </div>
  </section>
)
