import React from 'react'
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { pricing } from '../../skus_copy'
import PricingCard from '../PricingCard'
import axios from 'axios'
import { firebase, PROJECT_ID } from '../../firebase'
import './index.scss'
const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`

export default class PlanSelector extends React.Component {
  state = { helperText: 'Coupons are case sensitive' }

  applyCoupon = async () => {
    this.setState({ loading: true })
    const token = await firebase.auth().currentUser.getIdToken()
    const resp = await axios.post(
      `${HOST}/validateCoupon`,
      {
        coupon: this.state.coupon,
      },
      { headers: { authorization: 'Bearer ' + token } }
    )
    if (resp.data.valid) {
      this.setState({
        percentOff: resp.data.percent_off,
        validCoupon: resp.data.id,
        loading: false,
        error: false,
        helperText: `Yayy that worked! you got ${resp.data.percent_off}% off`,
      })
    } else {
      this.setState({
        error: true,
        helperText: 'Looks like the coupon has expired or is invalid',
        loading: false,
        percentOff: null,
        validCoupon: null,
      })
    }
  }

  render() {
    const {
      onPlanSelected,
      currentPlanNickname,
      keepCurrentPlan,
      paused,
    } = this.props

    const { helperText, error, loading, percentOff, validCoupon } = this.state
    return (
      <div className="purchase-form-wrapper">
        <Typography variant="h5" gutterBottom>
          {currentPlanNickname ? (
            'Upgrade your plan.'
          ) : (
            <span>
              Start your
              <span className="highlighted"> free </span> trail
            </span>
          )}
        </Typography>
        <Typography variant="overline" gutterBottom>
          Fair and transparent pricing ● No hidden fees ever ● Cancel Anytime ●
          No questions asked
        </Typography>
        <form autoComplete="off" className="coupon-form">
          <TextField
            className="coupon-field"
            required
            disabled={loading}
            error={error}
            helperText={helperText}
            id="outlined-Coupon"
            label="Coupon"
            onChange={e =>
              this.setState({
                coupon: e.target.value,
                error: false,
                helperText: 'Coupons are case sensitive',
              })
            }
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={this.applyCoupon}
            disabled={loading}
          >
            {loading ? (
              <span>
                <CircularProgress size={25} className="progress" /> Validaing...
              </span>
            ) : (
              'Apply Coupon'
            )}
          </Button>
        </form>
        <div className="pricing-blocks">
          {Object.keys(pricing).map(planName => {
            return currentPlanNickname &&
              pricing[planName].sku === 'free_plan' ? null : (
              <PricingCard
                key={planName}
                percentOff={percentOff}
                coupon={validCoupon}
                planName={planName}
                showTrail={!currentPlanNickname}
                card={pricing[planName]}
                currentPlan={planName === currentPlanNickname}
                onPlanSelected={onPlanSelected}
                keepCurrentPlan={keepCurrentPlan}
                paused={paused}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
