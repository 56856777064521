import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { firebase, firestore, STRIPE_ID } from '../../firebase'
import Auth from '../../components/Auth'
import ResumeSub from '../../components/ResumeSub'
import PaymentForm from './PaymentForm'
import { pricing } from '../../skus_copy'
import PlanSelecter from './PlanSelecter'
import PaymentCompleted from './PaymentCompleted'

import './index.scss'
import { Typography } from '@material-ui/core'

class PurchaseOverlay extends React.Component {
  state = {
    loading: true,
    signedIn: false,
    paused: false,
    user: {},
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.plan !== this.props.plan ||
      (!prevState.plan && this.props.plan && !this.state.plan)
    ) {
      this.setState({ plan: this.props.plan })
    }
    if (prevProps.open !== this.props.open && this.props.open) {
      this.checkAuth()
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(STRIPE_ID),
        loading: false,
      })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe(STRIPE_ID),
          loading: false,
        })
      })
    }
    this.checkAuth()
  }

  componentWillUnmount() {
    this.authSubscriber()
  }

  checkAuth = () => {
    this.authSubscriber = firebase.auth().onAuthStateChanged(async user => {
      this.setState({ loading: true })
      if (user) {
        const userRef = firestore.collection('users').doc(user.uid)
        const dbUser = await userRef.get()
        const subsSnap = await userRef.collection('subscriptions').get()
        const subRef = subsSnap.docs[0]
        let currentSub = { plan: {} }
        if (subRef) {
          currentSub = subRef.data()
        }
        this.setState({
          loading: false,
          signedIn: true,
          paused: (dbUser.data() || {}).pausedMonths,
          user: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
          },
          currentSubId: currentSub.id,
          currentPlanNickname: (currentSub.plan || {}).nickname,
          currentPlanId: (currentSub.plan || {}).id,
        })
      } else {
        this.setState({ loading: false, signedIn: false })
      }
    })
  }

  get title() {
    return this.state.signedIn ? 'Checkout' : 'Sign In or Create an Account'
  }

  get plan() {
    if (this.state.noChange) {
      return true
    } else if (this.state.plan) {
      return pricing[this.state.plan]
    } else {
      return false
    }
  }

  get closeAfterSignIn() {
    const close =
      this.props.signInOnly &&
      this.state.signedIn &&
      this.state.currentPlanNickname
    if (window.location.pathname === '/' && close) {
      window.location.href = '/app/workbench/'
    }
    return (
      this.props.signInOnly &&
      this.state.signedIn &&
      this.state.currentPlanNickname
    )
  }

  onPlanSelected = (plan, percentOff, coupon) => {
    this.setState({ plan, percentOff, coupon })
  }

  setLoadingState = props => {
    this.setState({ ...props })
  }

  onClose = () => {
    this.props.handleClose()
    this.setState({
      plan: null,
      paymentCompleted: false,
      signedIn: false,
      loading: false,
      noChange: false,
    })
  }

  keepCurrentPlan = e => {
    e.preventDefault()
    this.setState({ paymentCompleted: true, noChange: true })
  }

  render() {
    const { fullScreen, open } = this.props
    const {
      signedIn,
      loading,
      paymentCompleted,
      loadingMessage,
      currentPlanNickname,
      paused,
      percentOff,
      currentSubId,
      coupon,
    } = this.state
    return (
      <Dialog
        className="purchase-dialog"
        fullScreen={fullScreen}
        open={open && !this.closeAfterSignIn}
        maxWidth={'lg'}
        onClose={this.onClose}
        aria-labelledby="purchase-overlay"
      >
        <DialogTitle id="purchase-overlay">{this.title}</DialogTitle>
        <DialogContent>
          {!signedIn ? (
            <span>
              <DialogContentText>
                Sign in or create an account.
              </DialogContentText>
              <Auth />
            </span>
          ) : paused ? (
            <ResumeSub
              onResume={() => {
                this.setState({ paused: false })
              }}
            />
          ) : !this.plan ? (
            <PlanSelecter
              onPlanSelected={this.onPlanSelected}
              currentPlanNickname={currentPlanNickname}
              keepCurrentPlan={this.keepCurrentPlan}
              paused={paused}
            />
          ) : !paymentCompleted && this.plan.sku !== 'free_plan' ? (
            <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <PaymentForm
                  user={this.state.user}
                  plan={this.plan}
                  percentOff={percentOff}
                  setLoadingState={this.setLoadingState}
                  currentSubId={currentSubId}
                  coupon={coupon}
                  onSubCreated={() =>
                    this.setState({ paymentCompleted: true, loading: false })
                  }
                  changePlan={() => {
                    this.setState({ plan: null })
                  }}
                />
              </Elements>
            </StripeProvider>
          ) : (
            <PaymentCompleted
              userId={this.state.user.uid}
              freePlan={this.plan.sku === 'free_plan'}
            />
          )}

          {loading && (
            <div className="purchase-dialog-progress">
              <CircularProgress size={50} />
              <Typography variant="h6" className="msg">
                {loadingMessage}
              </Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}
export default withMobileDialog()(PurchaseOverlay)
