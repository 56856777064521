import React from "react";
import { Typography } from "@material-ui/core";

export default () => (
  <section className="section highlight">
    <div className="section-content">
      <div className="testimony-text">
        <Typography variant="h4" className="home-page-section-title">
          From us to you
        </Typography>
        <Typography variant="body1" gutterBottom>
          As fellow photographers and stock enthusiasts, we’re striving to make
          stock photography easier and more accessible to everyone. We are
          constantly improving our systems and the artificial intelligence that
          generates your tags is refined with every upload. It’s only getting
          better with every click of a shutter, and we’d love to have you on
          board!
        </Typography>
      </div>
    </div>
  </section>
);
