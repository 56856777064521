import React from 'react'
import { pricing } from '../../../skus_copy'
import PricingCard from '../../../components/PricingCard'
import { Typography } from '@material-ui/core'

class Pricing extends React.Component {
  render() {
    return (
      <section id="pricing" className="pricing">
        <div className="section-content">
          <div>
            <Typography variant="h4" className="home-page-section-title">
              Plans for <span className="home-page-highlight">Everyone</span>{' '}
            </Typography>
            <Typography variant="h6" className="home-feature-text">
              Fair and transparent pricing ● No hidden fees ever ● Cancel
              Anytime ● No Commitments
            </Typography>
          </div>
          <br />
          <div className="pricing-blocks">
            {Object.keys(pricing).map((pricingName, k) => {
              const card = pricing[pricingName]
              return (
                <PricingCard
                  key={k}
                  showTrail={true}
                  card={card}
                  pricingName={pricingName}
                  onPlanSelected={this.props.openAuthOverlay}
                />
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default Pricing
