import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { firebase, firestore } from '../../firebase'
import './index.scss'

const MONTH_IN_MIL_SEC = 2592000000

export default class Auth extends React.Component {
  constructor(props) {
    super(props)
    this.signInSuccessCallBack = this.signInSuccessCallBack.bind(this)
  }

  state = {
    firebaseuiLoaded: false,
  }

  signInSuccessCallBack = async authResult => {
    const user = {
      name: authResult.user.displayName,
      email: authResult.user.email,
      photoUrl: authResult.user.photoURL,
      emailVerified: authResult.user.emailVerified,
    }
    let refFrom = null
    debugger

    if (window.localStorage.refTime && window.localStorage.refCode) {
      if (Date.now() - window.localStorage.refTime < MONTH_IN_MIL_SEC) {
        refFrom = window.localStorage.refCode
        window.localStorage.clear()
      }
    }
    debugger

    const usersRef = firestore.collection('users').doc(authResult.user.uid)
    const docSnapshot = await usersRef.get()
    if (!docSnapshot.exists) {
      debugger
      await usersRef.set({ ...user, refFrom }, { merge: true })
    }
    return false
  }

  componentDidMount() {
    const firebaseui = require('firebaseui')
    const uiConfig = {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: authResult => {
          this.signInSuccessCallBack(authResult)
          return false
        },
        uiShown: () => {
          this.setState({ firebaseuiLoaded: true })
        },
      },
      signInFlow: 'popup',
      tosUrl: '/terms-of-service',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      privacyPolicyUrl: function() {
        window.location.assign('/privacy-policy')
      },
    }
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())
    ui.start('#firebaseui-auth-container', uiConfig)
  }

  render() {
    const { firebaseuiLoaded } = this.state
    return (
      <div>
        {!firebaseuiLoaded && <LinearProgress color="secondary" />}
        <div id="firebaseui-auth-container" />
      </div>
    )
  }
}
