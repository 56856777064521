import React from 'react'
import './index.scss'
import {
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone'
import { firestore, firebase, PROJECT_ID } from '../../firebase'
import highFive from '../../images/high_five.svg'
import SocialShare from '../SocialShare'
import axios from 'axios'

const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`
// const HOST = `http://localhost:5001/${PROJECT_ID}/us-central1`;

class PaymentCompleted extends React.Component {
  state = {}
  async componentDidMount() {
    this.listener = firestore
      .collection('users')
      .doc(this.props.userId)
      .onSnapshot(doc => {
        const user = doc.data()
        if (user.refToken) {
          const referalLink = `www.uploadmystock.com/?ref=${user.refToken}`
          this.setState({ referalLink })
        }
      })
    if (this.props.freePlan) {
      this.setState({ loading: true })
      const token = await firebase.auth().currentUser.getIdToken()
      await axios.post(
        `${HOST}/setupFreeAccount`,
        {},
        { headers: { authorization: 'Bearer ' + token } }
      )
    }
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (this.listener) {
      this.listener()
    }
  }
  render() {
    const { referalLink, copied, loading } = this.state
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img
            className="payment-graphic"
            src={highFive}
            alt="Payment sucess"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loading ? (
            <div className="loading">
              <CircularProgress />
              <Typography variant="h6" gutterBottom>
                Setting up your account...
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h4" gutterBottom>
                Thank you!
              </Typography>
              <Typography variant="h6" gutterBottom>
                Did you know you can earn extra cash every month by using your
                referral link?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Every time you share your referral link and someone signs up, we
                give you a cut every month. The more people who sign up through
                your link, the more you earn. It’s that simple. Share the link!
              </Typography>

              {referalLink ? (
                <div>
                  <SocialShare url={referalLink} />
                  <Typography variant="body2" className="referal-link">
                    {referalLink}
                    <CopyToClipboard
                      text={referalLink}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <IconButton
                        color="primary"
                        aria-label="Add to shopping cart"
                      >
                        <FileCopyTwoTone />
                      </IconButton>
                    </CopyToClipboard>
                  </Typography>

                  {copied && (
                    <Typography className="referal-link" variant="caption">
                      Copied!
                    </Typography>
                  )}
                </div>
              ) : (
                <CircularProgress />
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = '/app/workbench')}
              >
                Go to workbench
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    )
  }
}
export default PaymentCompleted
