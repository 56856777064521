exports.pricing = {
  free_plan: {
    sku: 'free_plan',
    title: 'Free',
    monthlyPrice: 'FREE',
    description: '',
    action: 'Start your free subscription',
    points: ['30 photos a month', '10 videos a month'],
    quota: {
      video: 10,
      image: 30,
      storage: 50,
    },
  },
  monthly_basic: {
    sku: 'plan_E2ex6uRphwp7RZ',
    title: 'Basic',
    monthlyPrice: 4.99,
    description: '',
    action: 'Start your free trial',
    points: ['60 photos a month', '30 videos a month'],
    quota: {
      video: 30,
      image: 60,
      storage: 50,
    },
  },
  monthly_plus: {
    sku: 'plan_E2eyg3SWm5blZk',
    title: 'Plus',
    monthlyPrice: 9.99,
    description: '',
    action: 'Start your free trial',
    points: ['200 photos a month', '100 videos a month'],
    quota: {
      video: 100,
      image: 200,
      storage: 200,
    },
  },
  monthly_pro: {
    sku: 'plan_E2ezx1uBWhjWl4',
    title: 'Pro',
    monthlyPrice: 19.99,
    description: '',
    action: 'Start your free trial',
    points: ['500 photos a month', '300 videos a month'],
    quota: {
      video: 300,
      image: 500,
      storage: 1024,
    },
  },
  monthly_expert: {
    sku: 'plan_E2ezusvGGM34hl',
    title: 'Expert',
    monthlyPrice: 49.99,
    description: '',
    action: 'Start your free trial',
    points: ['1000 photos a month', '1000 videos a month'],
    quota: {
      video: 1000,
      image: 1000,
      storage: 10240,
    },
  },
}
