import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PROJECT_ID, firebase } from '../../firebase'
import { Grid, Typography, Button } from '@material-ui/core'
import axios from 'axios'
import thankYou from '../../images/happy_thank_you.svg'
import './index.scss'
const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`

export default class ResumeSub extends React.Component {
  state = { loading: false }

  async componentDidMount() {
    const token = await firebase.auth().currentUser.getIdToken()
    this.setState({ token })
  }

  pauseSub = async () => {
    this.setState({
      loading: true,
      loadingMsg: `Resuming Your Subscription`,
    })

    await axios.post(
      `${HOST}/pauseSubscription`,
      {
        resume: true,
      },
      { headers: { authorization: 'Bearer ' + this.state.token } }
    )
    this.setState({
      loadingMsg: ``,
      loading: false,
    })
    this.props.onResume && this.props.onResume()
  }

  render() {
    const { loading, loadingMsg } = this.state
    return (
      <div className="resume-sub">
        <Grid container spacing={16}>
          <Grid xs={6} item>
            <img src={thankYou} alt="Welcome Back!" />
          </Grid>
          <Grid xs={6} item className="resume-text">
            {loading ? (
              <div>
                <CircularProgress />
                <Typography variant="subtitle2">{loadingMsg}</Typography>
              </div>
            ) : (
              <div>
                <Typography variant="h4" gutterBottom>
                  Welcome back!
                </Typography>
                <Typography variant="h6" gutterBottom>
                  We are glad you are back from your break! Click below to
                  resume your profile
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.pauseSub}
                >
                  Resume Subscription
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}
