import React from 'react'
import Footer from '../components/Footer'
import AppBar from '../components/AppBar'
import HeroSection from '../components/Home/HeroSection'
import UsecasesSection from '../components/Home/UsecasesSection'
import TestimonySection from '../components/Home/TestimonySection'
import PricingSection from '../components/Home/pricing'
import ReferalSection from '../components/Home/ReferalSection'
import PurchaseOverlay from '../components/PurchaseOverlay'
import Helmet from 'react-helmet'
import './index.scss'

class Home extends React.Component {
  state = {
    openAuthOverlay: false,
  }

  openAuthOverlay = (plan, signInOnly) => {
    this.setState({ openAuthOverlay: true, plan, signInOnly })
  }

  closeAuthOverlay = () => {
    this.setState({ openAuthOverlay: false })
  }

  render() {
    return (
      <div className="landing-page">
        <Helmet>
          <title>Upload My Stock</title>
          <meta
            name="Description"
            content="We generate the perfect tags for your content and let you upload to multiple agencies with one click."
          />
        </Helmet>
        <AppBar openAuthOverlay={this.openAuthOverlay} />
        <HeroSection openAuthOverlay={this.openAuthOverlay} />
        <UsecasesSection />
        <TestimonySection />
        <PricingSection openAuthOverlay={this.openAuthOverlay} />
        <ReferalSection openAuthOverlay={this.openAuthOverlay} />
        <Footer />
        {this.state.openAuthOverlay && (
          <PurchaseOverlay
            monthlyPrice={'monthlyPrice'}
            open={this.state.openAuthOverlay}
            plan={this.state.plan}
            signInOnly={this.state.signInOnly}
            handleClose={this.closeAuthOverlay}
          />
        )}
      </div>
    )
  }
}

export default Home
