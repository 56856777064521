import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import Currency from 'react-currency-formatter'
import './index.scss'

export default ({
  card,
  coupon,
  onPlanSelected,
  currentPlan,
  showTrail,
  keepCurrentPlan,
  paused,
  percentOff,
  planName,
}) => (
  <ButtonBase
    onClick={() => onPlanSelected(planName, percentOff, coupon)}
    className={`pricing-card ${card.prefered ? 'prefered' : ''}`}
  >
    {currentPlan && !paused && (
      <div className="current-plan" onClick={e => keepCurrentPlan(e)}>
        Keep Current Plan {paused}
      </div>
    )}
    <h3 className="pricing-card-title">{card.title}</h3>

    <div className="price-block">
      {showTrail && (
        <div className="free-trail">
          Free for {card.monthlyPrice !== 'FREE' ? '7 Days' : 'Ever'}!{' '}
        </div>
      )}
      <div
        className={`price-copy ${
          percentOff && !currentPlan && card.monthlyPrice !== 'FREE'
            ? 'strike'
            : ''
        }`}
      >
        {card.monthlyPrice !== 'FREE' && <sup>$</sup>}
        <span
          className={`home-page-highlight ${
            percentOff && !currentPlan && card.monthlyPrice !== 'FREE'
              ? 'strike'
              : ''
          }`}
        >
          {card.monthlyPrice}
        </span>

        {percentOff && !currentPlan && card.monthlyPrice !== 'FREE' && (
          <span className="discount-price">
            <Currency
              quantity={(card.monthlyPrice / (100 + percentOff)) * 100}
            />
          </span>
        )}
      </div>
      <div className="price-unit"> /Month</div>
    </div>
    {/* <div className="price-description">{card.description} </div> */}
    {card.points.map((points, i) => {
      return points.text ? (
        <div key={i} className="pricing-point">
          ✓ {points.text}
        </div>
      ) : (
        <div key={i} className="pricing-point">
          ✓ {points}
        </div>
      )
    })}
    <div color="primary" className="price-action">
      {card.action}
    </div>
  </ButtonBase>
)
