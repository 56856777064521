import React from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import {
  Grid,
  TextField,
  Button,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Typography,
  List,
} from '@material-ui/core'
import { firebase, firestore, PROJECT_ID } from '../../firebase'
import axios from 'axios'
import './purchaseForm.scss'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import AddIcon from '@material-ui/icons/Add'
import Currency from 'react-currency-formatter'
import stripeImage from '../../images/powered_by_stripe.png'
import AmericanExpress from '../../images/creditcards/AmericanExpress.png'
import creditcard from '../../images/creditcards/credit-card.png'
import DinersClub from '../../images/creditcards/DinersClub.png'
import Discover from '../../images/creditcards/Discover.png'
import JCB from '../../images/creditcards/JCB.png'
import MasterCard from '../../images/creditcards/MasterCard.png'
import Visa from '../../images/creditcards/Visa.png'

const HOST = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`
// const HOST = `http://localhost:5001/uploadmystock-staging/us-central1`
class PaymentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      cardComplete: false,
      newPayment: false,
      sources: [],
    }
  }

  async componentDidMount() {
    const userRef = firestore.collection('users').doc(this.props.user.uid)
    const paymentSnap = await userRef.collection('paymentSources').get()
    const sources = []
    paymentSnap.forEach(doc => {
      const { last4, id, brand, exp_year, exp_month } = doc.data()
      if (last4) {
        sources.push({ last4, id, brand, exp_year, exp_month })
      }
    })
    const newPayment = sources.length === 0 ? true : false
    this.setState({ sources, newPayment, loading: false })
  }

  toogleNewPayment = () => {
    this.setState({ newPayment: true })
  }

  cardUpdated = e => {
    this.setState({
      cardError: e.error,
      cardComplete: e.complete,
      paymentErrorMessage: false,
    })
  }

  async processPayment(sku, sourceId) {
    const token = await firebase.auth().currentUser.getIdToken()
    axios
      .post(
        `${HOST}/createSubscription`,
        {
          sku,
          ...sourceId,
          oldSubId: this.props.currentSubId || null,
          coupon: this.props.coupon || null,
        },
        { headers: { authorization: 'Bearer ' + token } }
      )
      .then(() => {
        this.props.onSubCreated()
      })
      .catch(error => {
        this.props.setLoadingState({
          loading: false,
          loadingMessage: false,
        })
        if (error.response && error.response.data) {
          this.setState({
            paymentErrorMessage:
              error.response.data.message || 'Payment error please try again',
          })
        } else {
          this.setState({
            paymentErrorMessage: 'Payment error please try again',
          })
        }
      })
  }

  submitPayment = async (sku, sourceId) => {
    this.props.setLoadingState({
      loading: true,
      paymentErrorMessage: false,
      loadingMessage: 'Processing your payment...',
    })
    const source = {}
    if (!sourceId) {
      const resp = await this.props.stripe.createToken({
        name: this.props.user.displayName,
      })
      source.token = resp.token.id
    } else {
      source.sourceId = sourceId
    }
    this.processPayment(sku, source)
  }

  render() {
    const {
      sources,
      loading,
      newPayment,
      paymentErrorMessage,
      cardError,
    } = this.state
    const { plan, changePlan, percentOff } = this.props
    const cardImages = {
      'American Express': AmericanExpress,
      'Diners Club': DinersClub,
      Discover: Discover,
      JCB: JCB,
      'Master Card': MasterCard,
      Visa: Visa,
    }

    return (
      <div>
        <Typography
          variant="overline"
          className="form-overline select-plan-title"
        >
          SELECTED PLAN
        </Typography>
        <Button
          size="small"
          className="change-plan-button"
          color="primary"
          onClick={changePlan}
        >
          Select another plan
        </Button>
        <Grid container spacing={32} className="payment-order-summary">
          <Grid item xs={3}>
            <Typography variant="h5"> {plan.title} </Typography>
          </Grid>
          <Grid item xs={5}>
            {plan.points.map((point, i) => {
              return (
                <Typography
                  key={i}
                  variant="subtitle2"
                  className="overlay-summary-point"
                >
                  ✓ {point}
                </Typography>
              )
            })}
          </Grid>
          <Grid item xs={4}>
            <span className="price-block">
              <span className="price-copy">
                {percentOff ? (
                  <Currency
                    quantity={(plan.monthlyPrice / (100 + percentOff)) * 100}
                  />
                ) : (
                  <Currency quantity={plan.monthlyPrice} />
                )}
              </span>

              <span className="price-unit"> /Month</span>
              {percentOff && (
                <div className="discounted-price">Discounted price!</div>
              )}
            </span>
          </Grid>
        </Grid>
        <Typography variant="overline" className="form-overline">
          SAFE & SECURE
        </Typography>

        <Grid container spacing={32}>
          <Grid item xs={3} />
          <Grid item xs={3} className="secure-mark-wrapper">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mcafeesecure.com/verify?host=uploadmystock.com"
            >
              <img
                className="mfes-trustmark secure-mark"
                border="0"
                src="//cdn.ywxi.net/meter/uploadmystock.com/102.svg"
                width="96"
                height="40"
                title="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
                alt="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
                onContextMenu={() => {
                  window.open(
                    'https://www.mcafeesecure.com/verify?host=uploadmystock.com'
                  )
                  return false
                }}
              />
            </a>
          </Grid>
          {/* <Grid item xs={4}>
            <Helmet>
              <script src="https://cdn.ywxi.net/js/inline.js?t=103" />
            </Helmet>
          </Grid> */}
          <Grid item xs={3} className="secure-mark-wrapper">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/us/customers"
            >
              <img
                alt="Stripe is a world renowed payment gatweay."
                className="secure-mark"
                src={stripeImage}
              />
            </a>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        <Typography variant="caption" className="securityText">
          This site is powered by Stripe and has earned the McAfee SECURE
          certification.
        </Typography>
        <div className="payment-information">
          <List component="div" disablePadding>
            {loading && (
              <div className="purchase-dialog-progress">
                <CircularProgress />
              </div>
            )}
            {sources.length > 0 && (
              <Typography variant="overline" className="form-overline">
                PAYMENT IN FILE
              </Typography>
            )}
            {sources.map(source => {
              return (
                <ListItem
                  key={source.id}
                  dense
                  button
                  onClick={() => this.submitPayment(plan.sku, source.id)}
                >
                  <ListItemIcon>
                    <img
                      className="purchase-form-card-logo"
                      alt={source.brand}
                      src={source.brand ? cardImages[source.brand] : creditcard}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`●●●● ${source.last4}`}
                    secondary={`${source.exp_month}/${source.exp_year}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => this.submitPayment(plan.sku, source.id)}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
            {!newPayment && (
              <ListItem dense button onClick={this.toogleNewPayment}>
                <ListItemIcon>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="NEW PAYMENT" />
                <ListItemSecondaryAction>
                  <IconButton onClick={this.toogleNewPayment}>
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
          {newPayment && (
            <div>
              <Typography variant="overline" className="form-overline">
                PAYMENT INFORMATION
              </Typography>
              <Grid container spacing={32}>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Name"
                    value={this.props.user.displayName}
                    margin="normal"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    label="Email"
                    value={this.props.user.email}
                    margin="normal"
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={32}>
                <Grid item xs={9}>
                  <CardElement onChange={this.cardUpdated} />
                </Grid>
                <Grid item xs={3}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mcafeesecure.com/verify?host=uploadmystock.com"
                  >
                    <img
                      className="mfes-trustmark"
                      border="0"
                      src="//cdn.ywxi.net/meter/uploadmystock.com/102.svg"
                      width="96"
                      height="40"
                      title="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
                      alt="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
                      onContextMenu={() => {
                        window.open(
                          'https://www.mcafeesecure.com/verify?host=uploadmystock.com'
                        )
                        return false
                      }}
                    />
                  </a>
                </Grid>
              </Grid>
              {cardError && (
                <span className="card-error">{cardError.message}</span>
              )}

              <Button
                className="purchase-form-button"
                variant="contained"
                color="primary"
                onClick={() => this.submitPayment(plan.sku)}
                disabled={!this.state.cardComplete || cardError}
              >
                Submit SECURE Payment
              </Button>
            </div>
          )}
          {paymentErrorMessage && (
            <span className="card-error">{paymentErrorMessage}</span>
          )}
        </div>
      </div>
    )
  }
}
export default injectStripe(PaymentForm)
