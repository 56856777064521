import React from 'react'
import Fab from '@material-ui/core/Fab'
import { Typography } from '@material-ui/core'

export default ({ openAuthOverlay }) => (
  <section className="section hero">
    <div className="section-content">
      <div className="home-page-hero-text">
        <h1 className="home-page-title">
          Tag and Upload Stock Photography Faster Than Ever.
        </h1>
        <Typography className="home-page-subtitle" gutterBottom>
          Generate the perfect keywords and upload to multiple
          agencies with one click.
        </Typography>
        <Fab
          variant="extended"
          color="secondary"
          aria-label="Try it out for free!"
          onClick={openAuthOverlay}
        >
          Try it out for free!
        </Fab>

        <p className="home-page-subtext">Cancel anytime, no commitments.</p>
      </div>
    </div>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 930 63"
      className="hero-border"
    >
      <path
        fill="#04726C"
        fillRule="evenodd"
        d="M0 38.4c18.8 2.4 33 3.7 42.6 3.9 28.6.6 57.8-2.1 87-3.9 65.8-4 85.5-23.6 197.2-24C438.6 13.9 444 37.3 548 37c104-.3 142.5-21.8 195.2-16.8 35.2 3.4 97.4 17.6 186.8 42.6V.5H0"
      />
    </svg>
  </section>
)
