import React from 'react'

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
} from 'react-share'
import {
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share'

const shareTitle = url =>
  `I'm using Upload My Stock to generate the perfect keywords and upload to all agencies at once! ${
    url ? `Get started here: ${url}` : ''
  }`
export default ({ url }) => (
  <div style={{ display: 'flex' }}>
    <FacebookShareButton
      style={{ margin: '10px' }}
      url={url}
      hashtag="#uploadmystock.com"
      quote={shareTitle(url)}
    >
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>
    <GooglePlusShareButton url={url} style={{ margin: '10px' }}>
      <GooglePlusIcon size={32} round={true} />
    </GooglePlusShareButton>
    <LinkedinShareButton
      url={url}
      style={{ margin: '10px' }}
      title={'Uploadmystock.com'}
      description={shareTitle(url)}
    >
      <LinkedinIcon size={32} round={true} />
    </LinkedinShareButton>
    <TwitterShareButton
      url={url}
      title={shareTitle(url)}
      style={{ margin: '10px' }}
      hashtags={['uploadmystock.com', 'stockphotographer', 'microstock']}
    >
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>
    <RedditShareButton
      url={url}
      style={{ margin: '10px' }}
      title={shareTitle()}
    >
      <RedditIcon size={32} round={true} />
    </RedditShareButton>
    <EmailShareButton
      url={url}
      style={{ margin: '10px' }}
      subject={'UploadMyStock.com'}
      body={shareTitle(url)}
    >
      <EmailIcon size={32} round={true} />
    </EmailShareButton>
  </div>
)
