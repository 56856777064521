import React from 'react'
import { Fab, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import referralImage from '../../images/home/referral.svg'
export default ({ openAuthOverlay }) => (
  <section id="referral" className="referral section highlight">
    <Grid container className="section-content">
      <Grid item xs={12} sm={6} className="referral-image-wrapper">
        <img
          className="referral-image"
          src={referralImage}
          alt="Referal Program"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="referral-text">
        <Typography variant="h4" className="home-page-section-title">
          Want an extra monthly income?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Every time you share your referral link and someone signs up,{' '}
          <span className="highlight">we give you a cut every month.</span> Just
          share the link and we'll take care of the rest.
        </Typography>
        <Fab
          variant="extended"
          color="secondary"
          aria-label="Create your account!"
          onClick={() => openAuthOverlay(undefined, true)}
        >
          Create your account!
        </Fab>
      </Grid>
    </Grid>
  </section>
)
